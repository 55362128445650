<template>
    <v-sheet elevation="0" class="py-3 px-0">
       <v-container fluid>
            <v-row>
                <v-col md="3" sm="3">
                    <h4>Item name</h4>
                </v-col>
                <v-col md="2" sm="2">
                    <h4>Variant</h4>
                </v-col>
                <v-col md="2" sm="2">
                        <h4>Comments</h4>
                </v-col>
                <v-col md="1" sm="1" style="text-align: center">
                        <h4>QTY</h4>
                </v-col>
                <v-col md="2" sm="2">
                        <h4>Price</h4>
                </v-col>
                <v-col md="2" sm="2">
                    <h4>Production Plan</h4>
                </v-col>
            </v-row>
            <div
                v-for="(product, index) in item.line_items"
                :key="product.product_name"
            >
            <v-row class="py-2">
                <v-col md="3" sm="3">
                    {{product.product_name}}
                </v-col>
                <v-col md="2" sm="2">
                    {{ product.variant ? formatProductVariant(product.variant) : "" }}
                </v-col>
                <v-col md="2" sm="2">
                    {{product.line_item_comment}}
                </v-col>
                <v-col md="1" sm="1" style="text-align: center">
                    {{product.item_qty}}
                </v-col>
                <v-col md="2" sm="2">
                    {{formatAsCurrency('R',product.product_price)}}
                </v-col>
                <v-col md="2" sm="2">
                    <v-chip :color="product.assigned_to_plan ? 'green lighten-4' : 'red lighten-4'" small>
                        <v-progress-circular v-if="product.loading" indeterminate color="primary" size="16"></v-progress-circular>
                        <template v-else>
                            {{ product.assigned_to_plan ? product.plan_number || 'Loading...' : 'Unassigned' }}
                        </template>
                    </v-chip>
                </v-col>
            </v-row>
            <v-divider
                v-if="index < item.line_items.length -1"
                :key="index"
            ></v-divider>
            </div>
       </v-container>

    </v-sheet>
</template>
<script>
import {formatAsCurrency} from '../../../composables/external'
export default {
    name: 'SalesOrderTableLineItemExpansion',
    props:['item'],
    data(){
        return{

        }
    },
    methods: {
        formatAsCurrency,
        formatProductVariant(variant) {
            const { variant_set_name, variant_name, variant_code } = variant;
            return `${variant_set_name} - ${variant_name} (${variant_code})`;
        }
    }
}
</script>
